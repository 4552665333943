export default {
  navTheme: "dark",
  primaryColor: "#1890ff",
  layout: "sidemenu",
  contentWidth: "Fixed", // layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  menu: {
    locale: false,
  },
  title: "升华双报系统",
  pwa: false,
  iconfontUrl: "",
  production:
    process.env.NODE_ENV === "production" &&
    process.env.VUE_APP_PREVIEW !== "true",
};
