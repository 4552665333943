import axios from "axios";
import { VueAxios } from "./axios";
import { notification } from "ant-design-vue";

import router from "./../router";
import storage from "store";
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.NODE_ENV == "development" ? "/api" : "/api",
  timeout: 6000, // 请求超时时间
});

const errorHandler = (error) => {
  if (error) {
    const data = error;
    // 从 localstorage 获取 token
    if (error.code === 502) {
      Notification.error({
        title: "提示",
        message: "网络问题！请联系管理员",
      });
    }
    if (error.code === 401 || error.code === 500) {
      notification.error({
        title: "提示",
        message: data.msg,
      });
    }
    if (error.code === 403) {
      notification.error({
        title: "提示",
        message: data.msg,
      });
      localStorage.removeItem("token");
      storage.clear();
      router.replace("/");
    }
  }
  return Promise.reject(error);
};

request.interceptors.request.use((config) => {
  config.headers = {
    "Content-Type": "application/json", //设置跨域头部 application/x-www-form-urlencoded
  };
  const token =localStorage.getItem('token')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  const { data } = response;
  if (data.code === 0) {
    return data.data;
  } else if ([402, 401, 403].includes(data.code)) {
    // 接口异常，直接刷新页面 -跳错误页
    notification.error({
      title: "提示",
      message: data.message,
      duration: 3 * 1000,
    });
    localStorage.removeItem("token");
    router.replace("/login");
  } else if (data.code == 502) {
    notification.error({
      title: "提示",
      duration: 3 * 1000,
      message: "网络问题！请联系管理员",
    });
  } else {
    console.log(data);
    notification.error({
      title: "提示",
      message: data.message,
      duration: 3 * 1000,
    });
    return Promise.reject(response);
  }
}, errorHandler);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  },
};
export const copy = (data) => {
  return new Promise((resolve, reject) => {
    console.log(data)
    const url = data
    const oInput = document.createElement('input')
    oInput.value = url
    document.body.appendChild(oInput)
    oInput.select() // 选择对象;
    document.execCommand('Copy') // 执行浏览器复制命令
    // this.$message.success('已复制！')
    oInput.remove()
    resolve(`复制成功：${data}`)
  })
}
export default request;

export { installer as VueAxios, request as axios };
