import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
// import VueAMap from 'vue-amap';
//
// Vue.use(VueAMap);
// // 初始化高德地图的 key 和插件
// VueAMap.initAMapApiLoader({
//   key: "df8fdd5fc134ce082072372382073ff5", // "key"
//   plugin: [
//     'AMap.Autocomplete', // 输入提示插件
//     'AMap.PlaceSearch', // POI搜索插件
//     'AMap.Scale', // 右下角缩略图插件 比例尺
//     'AMap.OverView', // 地图鹰眼插件
//     'AMap.ToolBar', // 地图鹰眼插件
//     'AMap.MapType', // 地图类型切换插件，可用于切换卫星地图
//     'AMap.PolyEditor', // 多边形编辑器，绘制和编辑折现
//     'AMap.CircleEditor', // 圆编辑插件
//     'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
//     'AMap.Geocoder' // 逆地理编码,通过经纬度获取地址所在位置详细信息
//     // 按实际需求添加插件
//   ],
//   v: '1.4.4', // amap版本（默认1.4.4）
//   // uiVersion: '1.0' // 地图ui版本（忽略，就是不配置）
// });
//
// //高德的安全密钥
// window._AMapSecurityConfig = {
//   securityJsCode:"8489360d77e373ce140e7b2876722753" // "安全密钥",
// }
import {copy} from '@/utils/request'
Vue.prototype.copy = copy
Vue.config.productionTip = false;
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import {
  VueAxios
} from './utils/request'
// 路由拦截
import './permission' // permission control
Vue.use(Antd)
Vue.use(VueAxios)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
