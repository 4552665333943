import Vue from "vue";
import VueRouter from "vue-router";
import defaultLayout from "./../layout/index";

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {},
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/',
    component: defaultLayout,
    redirect: '/report',
    children: [
      {
        path: '/report',
        name: '报备管理',
        component: () => import('../views/report/index.vue')
      },
      {
        path: "/role",
        name: "角色管理",
        component: () =>
          import("../views/role/index.vue"),
      },
      {
        path: "/menus",
        name: "菜单管理",
        component: () =>
            import("../views/menus/index.vue"),
      },
      {
        path: "/price",
        name: "价格管理",
        component: () =>
            import("../views/price/index.vue"),
      },
      {
        path: "/user",
        name: "用户管理",
        component: () =>
            import("../views/user/index.vue"),
      },
      {
        path: "/organization",
        name: '组织管理',
        component: () =>
            import("../views/organization/index.vue"),
      },
      {
        path: "/parameter",
        name: '参数管理',
        component: () =>
            import("../views/parameter/index.vue"),
      },
      {
        path: "/model",
        name: '规格管理',
        component: () =>
            import("../views/model/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
