import router from "./router"
// import store from "@/store"
const whiteList = ["404", "403", "500", "Login"];
const loginRoutePath = "/Login";
const defaultRoutePath = "/home";

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("token")) {
    var info = localStorage.getItem("token")
  }
  if (whiteList.includes(to.name)) {
    // 在免登录白名单，直接进入
    next();
  } else if (info) {
    if (to.path === loginRoutePath) {
      next({
        path: defaultRoutePath,
      });
    } else {
      next();
    }
  } else {
    next({
      path: loginRoutePath,
    });
  }
});
