import {
  axios
} from '@/utils/request'

const login = (parameter) => {
  return axios({
    url: '/auth/login',
    method: 'post',
    data: parameter
  })
}
const auth = (parameter) => {
  return axios({
    url: '/auth',
    method: 'get',
    params: parameter
  })
}
const update = (parameter) => {
  return axios({
    url: '/auth',
    method: 'put',
    data: parameter
  })
}
const logout = (parameter) => {
  return axios({
    url: '/auth',
    method: 'delete',
    params: parameter
  })
}
export {
  login,
  auth,
  logout,
  update
}
