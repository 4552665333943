<template>
  <a-layout id="layout">
    <a-layout-sider
      :title="title"
      :collapsed="collapsed"
      :mediaQuery="query"
      :isMobile="isMobile"
    >
      <span v-if="collapsed">
        <img class="image" :src="image" />
      </span>
      <!-- 标题 -->
      <div v-else class="title">{{ title }}</div>
      <a-menu theme="dark" mode="inline"  @select="onSelect" :selectedKeys="selectedKeys">
        <template v-for="tags in menus">
          <a-menu-item :key="tags.permissionUrl" v-if="!tags.children">
            <a-icon :type="tags.permissionIcon" />
            <span class="nav-text">{{tags.permissionName}}</span>
          </a-menu-item>
          <a-sub-menu :menu-info="tags.children" :key="tags.id" v-else>
            <span slot="title"><a-icon type="appstore" /><span>{{tags.permissionName}}</span></span>
            <a-menu-item v-for="tg in tags.children" :key="tg.permissionUrl">
              {{tg.permissionName}}
            </a-menu-item>
          </a-sub-menu>
        </template>

      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header :style="{ background: '#fff', padding: 0 }">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />

        <a-dropdown class="avatar-item" placement="bottomCenter">
          <span>
               <a-avatar
                       src="https://portrait.gitee.com/uploads/avatars/user/1611/4835367_Jmysy_1578975358.png"
               ></a-avatar>
              <span v-if="userName" class="name">{{userName}}</span>
          </span>
          <template #overlay>
            <a-menu class="avatar-dropdown">
              <a-menu-item key="0">
                <a-menu-item @click="go"> 修改密码</a-menu-item>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="3">
                <a-menu-item @click="logout"> 注销登录</a-menu-item>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

      </a-layout-header>
      <div>
        <a-tabs
          hide-add
         :activeKey="activeKey"
          @change="onChange"
          @edit="onEdit"
          class="tab"
          size="small"
          type="editable-card"
        >
          <a-tab-pane
            v-for="item in panes"
            :key="item.path"
            :closable="panes.length > 1"
          >
            <template #tab>
              <span class="tab-dot"></span>
              {{ item.name }}
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>
      <a-layout-content :style="{ margin: '24px 16px 0', }">
        <div
          :style="{ padding: '24px', background: '#fff', minHeight: '360px' }"
        >
          <transition name="fade-transform" mode="out-in">
            <router-view />
          </transition>
        </div>
      </a-layout-content>
    </a-layout>
    <a-modal
        title="密码修改"
        :visible="visible"
        :confirm-loading="confirmLoading"
        ok-text="确认"
        cancel-text="取消"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="原密码" v-bind="formItemLayout">
          <a-input-password
              size="large"
              placeholder="请输入原密码"
              v-decorator="[
                'oldPwd',
                {rules: [{ required:true, message: '请输入原密码' },  { pattern:  /^.{3,6}$/, message: '请输入3-6位密码！' },], validateTrigger: 'blur'}
              ]"
          >
          </a-input-password>
        </a-form-item>
        <a-form-item label="新密码" v-bind="formItemLayout">
          <a-input-password
              size="large"
              placeholder="请输入密码"
              v-decorator="[
                'newPwd',
                {rules: [{ required:true, message: '请输入密码' },{ pattern:  /^.{3,6}$/, message: '请输入3-6位密码！' }], validateTrigger: 'blur'}
              ]"
          >
          </a-input-password>
        </a-form-item>
        <a-form-item label="确认新密码" v-bind="formItemLayout">
          <a-input-password
              size="large"
              placeholder="确认新密码"
              v-decorator="[
                'qoldPwd',
                {rules: [{ required:true, message: '确认新密码' },{ pattern:  /^.{3,6}$/, message: '请输入3-6位密码！' }], validateTrigger: 'blur'}
              ]"
          >
          </a-input-password>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout>
</template>
<script>
import defaultSettings from "@/config/defaultSettings";
import {logout,update} from '@/api/login'
import {add} from "@/api/menus";
import md5 from 'js-md5';
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      confirmLoading: false,
      formItemLayout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      activeKey:this.$route.path,
      panes: [],
      selectedKeys: [],
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // 定宽: true / 流式: false
        contentWidth:
          defaultSettings.layout === "sidemenu"
            ? false
            : defaultSettings.contentWidth === "Fixed",
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,
        image:'',
        hideHintAlert: false,
        hideCopyButton: false,
      },
      // 媒体查询
      query: {},
      visible:false,
      // 是否手机模式
      isMobile: false,
    };
  },
  watch: {
    $route() {
      this.selectedKeys = [this.$route.path]
      const name = this.$route.name;
      const path = this.$route.path;
      this.add({ name, path })
    }
  },
  computed: {
    userName() {
      return this.$store.state.userName
    },
    menus() {
      return this.$store.state.info
    }
  },
  created() {
    this.activeKey =  this.$route.path
    this.selectedKeys=[this.$route.path]
    const name = this.$route.name;
    const path = this.$route.path;
    this.panes=[
      {
        name,
        path
      }
    ]

  },
  methods: {
    handleOk() {
      this.form.validateFields(async (err, values) => {
        this.confirmLoading = true;
        if (!err) {
          if(values.qoldPwd!==values.newPwd){
            this.$message.error('新密码与确认密码不相同！');
            this.confirmLoading = false;
            return
          }
          values.newPwd = md5(values.newPwd)
          values.oldPwd = md5(values.oldPwd)
          delete values.qoldPwd
          update(values).then((res) => {
            this.confirmLoading = false;
            this.form.resetFields()
            this.$message.success('操作成功');
            this.handleCancel()
          })
        }
      })
    },
    add(tab) {
      !this.panes.find((item)=> item.path === tab.path) && this.panes.push(tab);
      this.activeKey = tab.path;
    },
    onEdit(targetKey, action) {
        console.log(action)
      if (action === "remove") {
        this.remove(targetKey);
      }
    },
    remove(targetKey) {
      let activeKey = this.activeKey;
      let lastIndex;
      this.panes.forEach((pane, i) => {
        if (pane.path === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = this.panes.filter(pane => pane.path !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].path;
          this.$router.push({ path:  panes[lastIndex].path })
        } else {
          activeKey = panes[0].path;
          this.$router.push({ path:  panes[0].path })
        }
      }
      this.panes = panes;
      this.activeKey = activeKey;
      this.selectedKeys.splice(0,1,activeKey)
    },
    onChange(path) {
      this.$router.push({ path:path });
    },
    go(){
      this.visible = true
    },
    handleCancel(){
      this.visible = false
      this.confirmLoading = false
      this.form.resetFields()
    },
    onSelect(key) {
      this.$router.push({
        path:key.key
      })
    },
    logout() {
      this.$confirm({
        okText: '确定',
        cancelText: '取消',
        title: '消息提示',
        content: '确定退出登陆？',
        okType: 'danger',
        onOk: () => {
          logout().then((res)=>{
            localStorage.removeItem("token",'')
            localStorage.removeItem("info",'')
            localStorage.removeItem("userInfo",'')
            localStorage.removeItem("userName",'')
            location.reload()
          })
        },
        onCancel () {}
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.ant-layout {
  height: 100%;
}
.tab-dot {
  width: 8px;
  height: 8px;
  background: #f5f5f5;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  top: -1px;
}
.ant-tabs-tab-active {
  .tab-dot {
    background: #2d8cf0;
  }
}
  .ant-tabs-tab-btn {
    color: #808695 !important;
  }

  .trigger {
    font-size: 26px;
    margin: auto 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

.trigger:hover {
  color: #1890ff;
}

.avatar-item {
  float: right;
  height: 60px;
  width: auto;
  margin: 5px 24px;
  display: inline-block;
  padding-bottom: 15px;
  padding-right: 5px;
  padding-left: 5px;

  img {
    border-radius: 100px;
  }
}
.image {
  margin-top: 11px;
  height: 38px;
}
.title {
  display: inline-block;
  color: #1890ff !important;
  font-size: 23px;
  margin-top: 2px;
  height: 60px;
  font-weight: 700;
  line-height: 53px;
  text-align: center;
  width: 100%;
}
  .name{
    font-size: 14px;
    font-weight: 500;
    color: #2c3e50;
  }
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
  background: #2c3e50!important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{

}
</style>
