import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    info:JSON.parse(localStorage.getItem('info')),
    userName:localStorage.getItem('userName')
  },
  mutations: {
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_USERNAME: (state, name) => {
      state.userName = name
    }
  },
  actions: {},
  modules: {},
});
